<template>
  <div>
    <div class="main">
      <v-img dark height="100vh" justify="center" align="center">
        <div class="imgDiv pa-5">
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="12">
              <v-btn small class="mb-15" rounded color="#ff7420" light>
                Over 300 thousands ready-to-work Riders !
              </v-btn>
              <h1 class="text-h3 font-weight-bold mb-4">Shippay Logistics</h1>
              <h4 class="subheading">We find the path!</h4>
            </v-col>
            <v-col class="text-center pa-3" md="4" sm="12" cols="12">
              <div class="downloadapp">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="appstore ma-5">
                      <img
                        src="../assets/google.png"
                        alt="google logo"
                        width="133"
                        height="40"
                      />
                    </div>
                  </template>
                  <span>Google Play Store</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" class="appstore ma-5">
                      <img
                        src="../assets/apple.png"
                        alt="apple logo"
                        width="133"
                        height="40"
                      />
                    </div>
                  </template>
                  <span>App Store</span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-img>
      <v-row>
        <v-col class="text-center" md="6" cols="12">
          <div dark height="400" width="400">
            <div
              class="text-center display-2 ma-10 font-weight-bold white--text"
            >
              We Move You Forward
            </div>
            <div class="white--text pa-5">
              Our vision is simple yet powerful: to make logistics smarter, more
              efficient, and environmentally sustainable. We envision a world
              where the movement of goods is seamless, cost-effective, and
              environmentally responsible, thanks to innovative technology
              solutions.
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="#ff7420" x-large
                  >Get Started</v-btn
                >
              </template>
              <span>Download our App to Continue</span>
            </v-tooltip>
          </div>
        </v-col>
        <v-col class="text-center" md="6" cols="12">
          <div dark height="400" width="400">
            <v-img
              height="100%"
              width="100%"
              src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/rider.png?alt=media&token=846f45ba-fb7d-48dd-a2d1-0871383e3a35"
            ></v-img>
          </div>
        </v-col>
      </v-row>
    </div>
    <HomeSildeVue />
  </div>
</template>
<script>
import HomeSildeVue from "./HomeSilde.vue";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      parcelData: [],
      trackingNumber: "",
    };
  },
  components: { HomeSildeVue },
  methods: {},
};
</script>

<style scoped>
.downloadapp {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(10, 10, 10, 0.301);
  height: 100%;
}
</style>