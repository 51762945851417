<template>
  <div class="mx-auto">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-parallax
      dark
      height="600"
      src="https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-ketut-subiyanto-4246120.jpg?alt=media&token=10689f9f-b3bf-45fd-bb48-64dd6d59069e"
    >
      <v-row align="center" justify="center">
        <v-col class="text-center" cols="12">
          <h1 class="text-h4 font-weight-bold mb-4">
            On-demand delivery for your business
          </h1>
          <h4 class="subheading">
            Tech and logistics infrastructure to help enterprises scale delivery
          </h4>
        </v-col>
      </v-row>
    </v-parallax>
    <div class="text-center display-2 ma-10 font-weight-bold white--text">
      Seamless delivery services
    </div>
    <v-container>
      <v-row>
        <v-col v-for="i in CoreValue" :key="i.text" cols="12" sm="4">
          <v-hover v-slot="{ hover }" close-delay="200">
            <v-card
              :elevation="hover ? 16 : 2"
              :class="{ 'on-hover': hover }"
              class="mx-auto"
              height="450"
              max-width="450"
            >
              <v-img height="200" :src="i.img"></v-img>
              <v-card-text class="font-weight-bold display-1 mt-5 text-center">
                {{ i.text }}
              </v-card-text>
              <div class="text-center display-2 mt-5 font-weight-bold">
                <v-btn class="ma-2" large fab :color="i.color">
                  <v-icon :color="i.color2" size="45"> {{ i.icon }} </v-icon>
                </v-btn>
              </div>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
      <div class="text-center display-2 ma-10">
        <v-divider class="mx-4"></v-divider>

        <div class="text-center display-2 ma-10 font-weight-bold white--text">
          We deliver it in minutes
        </div>

        <v-card-text>
          <v-chip-group
            v-model="selection"
            active-class="orange white--text"
            column
          >
            <v-chip>5:30PM</v-chip>

            <v-chip>7:30PM</v-chip>

            <v-chip>8:00PM</v-chip>

            <v-chip>9:00PM</v-chip>
          </v-chip-group>
        </v-card-text>
        <v-divider class="mx-4"></v-divider>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false,
    selection: 1,
    CoreValue: [
      {
        icon: "mdi-warehouse",
        text: "Warehouse Solutions",
        img: "https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-tiger-lily-4484072.jpg?alt=media&token=0e8d2206-210c-4948-8215-1340f6e9041d",
        color: "#ff7420",
        color2: "#181818",
      },
      {
        icon: "mdi-transit-connection-variant",
        text: "Transport and Distribution",
        img: "https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-tiger-lily-4481258.jpg?alt=media&token=8bfcc95f-a55d-45d7-bd79-029b864ce480",
        color: "#181818",
        color2: "#ff7420",
      },
      {
        icon: "mdi-truck-delivery",
        text: "Contract Logistics",
        img: "https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-norma-mortenson-4391478.jpg?alt=media&token=925c47c9-de98-42d6-9ed6-c9f6e55deb26",
        color: "#ff7420",
        color2: "#181818",
      },
      {
        icon: "mdi-access-point-network",
        text: "World-class tech",
        img: "https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-thisisengineering-3862132.jpg?alt=media&token=fc5379f2-7a3b-4508-b5d2-d6026b565db3",
        color: "#181818",
        color2: "#ff7420",
      },
      {
        icon: "mdi-clipboard-list-outline",
        text: "Order Management",
        img: "https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-andrea-piacquadio-3769747.jpg?alt=media&token=43ce94fa-10ab-4a18-a056-c174cedcc165",
        color: "#ff7420",
        color2: "#181818",
      },
      {
        icon: "mdi-shopping",
        text: "E-commerce Delivery",
        img: "https://firebasestorage.googleapis.com/v0/b/golegit-ce962.appspot.com/o/pexels-artem-podrez-5025517.jpg?alt=media&token=29dbbb5d-5383-4c6b-9402-520a2af3c1eb",
        color: "#181818",
        color2: "#ff7420",
      },
    ],
  }),

  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
  },
};
</script>