<template>
  <v-footer dark padless>
    <v-card flat dark class="white--text text-center">
      <v-card-text>
        <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
          <v-icon color="#ff7420" size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        In an era of rapid globalization and digital transformation, the
        logistics industry stands on the cusp of a major revolution. At Shippay,
        we're at the forefront of this change, harnessing the power of
        technology to redefine how goods are transported, managed, and
        delivered. <br />
        Cutting-Edge Technology: We're not your ordinary logistics company.
        We're a tech company with logistics at its core. Our proprietary
        technology stack, including state-of-the-art route optimization,
        real-time tracking, and data analytics, sets us apart.
      </v-card-text>
      <v-row>
        <v-col class="text-center pa-3" md="4" sm="12" cols="12">
          <div class="downloadapp">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="appstore ma-2">
                  <img
                    src="../assets/google.png"
                    alt="google logo"
                    width="133"
                    height="40"
                  />
                </div>
              </template>
              <span>Google Play Store</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="appstore ma-2">
                  <img
                    src="../assets/apple.png"
                    alt="apple logo"
                    width="133"
                    height="40"
                  />
                </div>
              </template>
              <span>App Store</span>
            </v-tooltip>
          </div>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>Shippay</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
  <script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
  }),
};
</script>

<style scoped>
.downloadapp {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>