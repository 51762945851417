<template>
  <v-app>
    <BackgroundPatical />
    <v-app-bar app color="#ff7420" dark>
      <div class="d-flex align-center mr-10">
        <v-img
          alt="shippay Logo"
          class="shrink mr-2"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/icon.png?alt=media&token=9532eb27-37fc-4aba-99bc-4418923f85bf"
          width="60"
        />
      </div>

      <v-menu offset-y class="middleDiv">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-3" text light v-bind="attrs" v-on="on">
            Partner
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Become a Rider </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Become a Logistic Partner </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>Become a Business Partner </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      |
      <v-menu offset-y class="middleDiv">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-3" text light v-bind="attrs" v-on="on">
            Company
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title>Our Vision </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>About Us </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            outlined
            color="black"
            class="ma-2 middleDiv"
          >
            <span class="mr-2">Login</span>
            <v-icon>mdi-login</v-icon>
          </v-btn>
        </template>
        <span>Download our App to Continue</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            color="black"
            class="ma-2 middleDiv"
          >
            <span class="mr-2">Sign up</span>
          </v-btn>
        </template>
        <span>Download our App to Continue</span>
      </v-tooltip>
    </v-app-bar>

    <v-main>
      <LandingPage />
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import LandingPage from "./components/LandingPage.vue";
import BackgroundPatical from "./components/BackgroundPatical.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  components: {
    LandingPage,
    BackgroundPatical,
    Footer,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
body::-webkit-scrollbar {
  width: 0.4em;
}
body::-webkit-scrollbar-thumb {
  background-color: #181818;
  border-radius: 10px;
}
@media only screen and (max-width: 800px) {
  .middleDiv {
    display: none;
  }
}
</style>
