var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"main"},[_c('v-img',{attrs:{"dark":"","height":"100vh","justify":"center","align":"center"}},[_c('div',{staticClass:"imgDiv pa-5"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mb-15",attrs:{"small":"","rounded":"","color":"#ff7420","light":""}},[_vm._v(" Over 300 thousands ready-to-work Riders ! ")]),_c('h1',{staticClass:"text-h3 font-weight-bold mb-4"},[_vm._v("Shippay Logistics")]),_c('h4',{staticClass:"subheading"},[_vm._v("We find the path!")])],1),_c('v-col',{staticClass:"text-center pa-3",attrs:{"md":"4","sm":"12","cols":"12"}},[_c('div',{staticClass:"downloadapp"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"appstore ma-5"},'div',attrs,false),on),[_c('img',{attrs:{"src":require("../assets/google.png"),"alt":"google logo","width":"133","height":"40"}})])]}}])},[_c('span',[_vm._v("Google Play Store")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"appstore ma-5"},'div',attrs,false),on),[_c('img',{attrs:{"src":require("../assets/apple.png"),"alt":"apple logo","width":"133","height":"40"}})])]}}])},[_c('span',[_vm._v("App Store")])])],1)])],1)],1)]),_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"md":"6","cols":"12"}},[_c('div',{attrs:{"dark":"","height":"400","width":"400"}},[_c('div',{staticClass:"text-center display-2 ma-10 font-weight-bold white--text"},[_vm._v(" We Move You Forward ")]),_c('div',{staticClass:"white--text pa-5"},[_vm._v(" Our vision is simple yet powerful: to make logistics smarter, more efficient, and environmentally sustainable. We envision a world where the movement of goods is seamless, cost-effective, and environmentally responsible, thanks to innovative technology solutions. ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"#ff7420","x-large":""}},'v-btn',attrs,false),on),[_vm._v("Get Started")])]}}])},[_c('span',[_vm._v("Download our App to Continue")])])],1)]),_c('v-col',{staticClass:"text-center",attrs:{"md":"6","cols":"12"}},[_c('div',{attrs:{"dark":"","height":"400","width":"400"}},[_c('v-img',{attrs:{"height":"100%","width":"100%","src":"https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/rider.png?alt=media&token=846f45ba-fb7d-48dd-a2d1-0871383e3a35"}})],1)])],1)],1),_c('HomeSildeVue')],1)}
var staticRenderFns = []

export { render, staticRenderFns }