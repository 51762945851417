var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('BackgroundPatical'),_c('v-app-bar',{attrs:{"app":"","color":"#ff7420","dark":""}},[_c('div',{staticClass:"d-flex align-center mr-10"},[_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"shippay Logo","contain":"","src":"https://firebasestorage.googleapis.com/v0/b/haulee-1b003.appspot.com/o/icon.png?alt=media&token=9532eb27-37fc-4aba-99bc-4418923f85bf","width":"60"}})],1),_c('v-menu',{staticClass:"middleDiv",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"text":"","light":""}},'v-btn',attrs,false),on),[_vm._v(" Partner ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Become a Rider ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Become a Logistic Partner ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("Become a Business Partner ")])],1)],1)],1),_vm._v(" | "),_c('v-menu',{staticClass:"middleDiv",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-3",attrs:{"text":"","light":""}},'v-btn',attrs,false),on),[_vm._v(" Company ")])]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Our Vision ")])],1),_c('v-list-item',[_c('v-list-item-title',[_vm._v("About Us ")])],1)],1)],1),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 middleDiv",attrs:{"small":"","outlined":"","color":"black"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"mr-2"},[_vm._v("Login")]),_c('v-icon',[_vm._v("mdi-login")])],1)]}}])},[_c('span',[_vm._v("Download our App to Continue")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 middleDiv",attrs:{"small":"","color":"black"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"mr-2"},[_vm._v("Sign up")])])]}}])},[_c('span',[_vm._v("Download our App to Continue")])])],1),_c('v-main',[_c('LandingPage'),_c('Footer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }